import React from 'react';
import PropTypes from 'prop-types';
import { Button, Overlay, Image } from '@thd-olt-component-react/core-ui';
import { CARD_IMAGE } from '../../utils/constants';
import './intro-offer-apply-modal.style.scss';
import { clickEvent } from '../../utils/publisher';

export const IntroOfferApplyModal = (
  {
    closeModal,
    applyNowUrl,
    isMobile,
    analyticsFeature,
    isB2B
  }) => {

  const redirectToCiti = () => {
    clickEvent(analyticsFeature);
    window.location.href = applyNowUrl;
  };

  const getDefaultPromoEndDate = () => {
    if (window?.THD?.PaymentEstimator?.defaultPromoEndDate
        && typeof window?.THD?.PaymentEstimator?.defaultPromoEndDate === 'function'
    ) {
      return window?.THD?.PaymentEstimator?.defaultPromoEndDate();
    }
    return '';

  };

  const paymentEstimatorOffer = () => {
    return (
      <p className="u--paddingBottom apply-now-line-spacing">
        Get<span className="u__bold"> $25 off⬦</span> purchases of $25-$299,
        <span className="u__bold"> $50 off⬦</span> purchases of $300-999
        or <span className="u__bold">$100 off⬦</span> purchases of $1000 or more
        on your next qualifying purchase when you open a new card.
        {!isB2B ? (
          <span> Plus, receive <strong>6-months everyday financing* </strong>on purchases $299 or
            more when you use your Consumer Card.
          </span>
        ) : null}
      </p>
    );
  };
  const modalTitle = () => {
    return (
      <>
        <h3 className="u__text-align--center u--paddingTop u--paddingBottom plcc-modal-title">
          ENJOY SPECIAL SAVINGS WITH YOUR NEW ACCOUNT
        </h3>
        <h3 className="u__text-align--center u--paddingBottom">
          Save up to $100◊ on qualifying purchases.
        </h3>
      </>
    );
  };

  return (
    <Overlay
      modalClass="apply-now-overlay wd-640"
      closeButton
      isMobile={isMobile}
      open
      onClose={closeModal}
    >
      <div className="apply-now-modal-message">
        <div className="u__text-align--center">
          <Image
            src={isB2B ? CARD_IMAGE.PLCR_HOMEDEPOT : CARD_IMAGE.PLCN_HOMEDEPOT}
            className="u--padding intro-offer-apply-now-card-image"
            alt="Credit Card Icon"
            width="105"
            height="73"
          />
        </div>
        {modalTitle()}
        <hr />
        {/* {displayIntroOffer && introOffer()} */}
        { paymentEstimatorOffer()}

        { !isB2B ? (
          <p className="apply-now-line-spacing u--paddingTop u--paddingBottom">
            <span className="u__bold">No Interest If Paid in Full Within 6 Months*</span> on
            purchases of $299 or more made on The Home Depot Consumer Credit Card.
            Interest will be charged to your account from the purchase date
            if the purchase balance (including premiums for optional credit insurance) is not paid in
            full within 6 months.
          </p>
        ) : null }

        { !isB2B ? (
          <p className="apply-now-line-spacing u--paddingTop u--paddingBottom u__bold">
            *With credit approval for qualifying purchases made on The Home Depot or EXPO Design
            Center Consumer Credit Card. APR: 17.99% - 26.99%. Minimum interest charge: $2. See
            card agreement for details including APR applicable to you. Offer valid for consumer
            accounts in good standing; 6 months everyday credit offer is subject to change without
            notice; see store for details.
          </p>
        ) : null }

        <div className="u--paddingBottom u--paddingTop apply-now-button-container">
          <Button onClick={redirectToCiti}>
            Apply Now
          </Button>
        </div>

      </div>
      <div className="u--padding apply-now-terms-and-conditions apply-now-line-spacing">
        <div className="u__flex">
          <p className="u--paddingTop">
            <span className="u__bold">
              ◊Offer is for new accounts, is subject to credit approval
              and valid now through {getDefaultPromoEndDate()}.&nbsp;
            </span>
            This offer is redeemable for either $25 discount on single receipt purchase of $25 upto $299,
            a $50 discount on single receipt purchase of $300 upto $999 or a $100 discount on single receipt purchase of
            $1,000 or more, excluding tax and delivery fees, within 30 days of your
            account open date at The Home Depot® retail stores or
            homedepot.com when made with your The Home Depot Consumer Credit
            Card, The Home Depot Home Improver Card, The Home Depot
            Commercial Revolving Charge, or The Home Depot Commercial
            Account. Valid in the U.S., U.S.V.I., Puerto Rico and Guam.
            Offer does not apply to prior purchases, The Home Depot Gift
            Cards or Certificates, third party gift cards, installation
            products or services purchased in home, Tool Rental or to Traeger®
            Weber®, Bona®, Simplehuman®, Dacor®, Viking®, Fisher & Paykel®,
            Sharp Insight™, Delonghi®, Buckhaven, Lynx®, Alfresco™, OCI,
            Marvel®, Bertazzoni, Aga, Vent-A-Hood®, Samsung Chef Collection,
            Bosch Benchmark® Series, Liebherr, Zephyr, Miele, Signature
            Kitchen Suite, Jenn-Air®, Thermador®, Jeld-win Door Systems,
            Monogram products and trade styles.
          </p>
        </div>
      </div>
    </Overlay>
  );
};

IntroOfferApplyModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  applyNowUrl: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isB2B: PropTypes.bool,
  analyticsFeature: PropTypes.string,
};

IntroOfferApplyModal.defaultProps = {
  analyticsFeature: 'plcc-est',
  isB2B: false,
};
